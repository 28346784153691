import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { subDays } from "date-fns";
import React, { Dispatch, SetStateAction } from "react";
import { CisCalendarsResponse } from "@/ModuleCalendarToIncomeStatement/CisUtils";

interface Props {
    selectedCalendar: string;
    setSelectedCalendar: Dispatch<SetStateAction<string>>;

    startDate: Date;
    setStartDate: Dispatch<SetStateAction<Date>>;

    endDate: Date;
    setEndDate: Dispatch<SetStateAction<Date>>;

    allCalendars: CisCalendarsResponse | null;
}

export function CisNav(props: Props) {
    return <div className="flex flex-row items-center gap-2">
        <FormControl sx={{ minWidth: "200px" }}>
            <InputLabel id="demo-simple-select-label">Kalender auswählen</InputLabel>
            <Select
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.selectedCalendar}
                label="Kalender auswählen"
                onChange={event => {
                    props.setSelectedCalendar(event.target.value);
                }}
            >
                {props.allCalendars?.items?.map(calendar => (
                    <MenuItem key={calendar.id} value={calendar.id}>
                        {calendar.summary}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

        {!!props.selectedCalendar && <>
            <DatePicker
                renderInput={e => <TextField {...e}/>}
                label="Start"
                value={props.startDate}
                onChange={(newValue) => props.setStartDate(newValue || subDays(new Date(), 30))}
            />
            <DatePicker
                renderInput={e => <TextField {...e}/>}
                label="Ende"
                value={props.endDate}
                onChange={(newValue) => props.setEndDate(newValue || new Date())}
            />
        </>}
    </div>;
}
