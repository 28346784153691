import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import React, { Dispatch, SetStateAction } from "react";
import { getAuth, GoogleAuthProvider, linkWithPopup, signInWithPopup } from "firebase/auth";
import { CisAccessTkn } from "@/ModuleCalendarToIncomeStatement/CISMainPage";

interface Props {
    setAccessTkn: Dispatch<SetStateAction<CisAccessTkn>>;
    isDevMode: boolean;
}

export function CisGoogleAuth(props: Props) {
    // TODO: Show "mit google verlinken" only if user is not already linked with google.
    const linkWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        console.log("get scopes 1", provider.getScopes());
        provider.addScope("https://www.googleapis.com/auth/calendar.readonly");
        provider.addScope("https://www.googleapis.com/auth/calendar.events.readonly");
        console.log("get scopes 2", provider.getScopes());

        const auth = getAuth();
        if (auth.currentUser) {
            linkWithPopup(auth.currentUser, provider)
                .then(result => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    props.setAccessTkn(credential?.accessToken || "");
                    console.log("LINK WITH GOOGLE SUCCESS", result.user, credential, credential?.accessToken);
                })
                .catch((error) => {
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    console.log("LINK WITH GOOGLE ERROR", error.code, error.message, error.customData.email, credential);
                });
        } else {
            console.error("(LINK WITH GOOGLE) AUTH is null"); // TODO: add error message in ui
        }
    };

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });

        console.log("get scopes 1", provider.getScopes());
        provider.addScope("https://www.googleapis.com/auth/calendar.readonly");
        provider.addScope("https://www.googleapis.com/auth/calendar.events.readonly");
        console.log("get scopes 2", provider.getScopes());

        signInWithPopup(getAuth(), provider)
            .then(result => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                props.setAccessTkn(credential?.accessToken || "");
                console.log("SUCCESS", result.user, credential, credential?.accessToken);
            })
            .catch((error) => {
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.log("ERROR", error.code, error.message, error.customData.email, credential);
            });
    };

    return <div>
        {props.isDevMode ?
            <Button onClick={() => props.setAccessTkn("DEV_ACCESS_TOKEN")}>
                Set dev access token ("logging in to google")
            </Button>
            :
            <>
                <Button
                    startIcon={<GoogleIcon/>}
                    variant="outlined"
                    onClick={linkWithGoogle}
                >
                    Mit Google verlinken
                </Button>

                <Button
                    startIcon={<GoogleIcon/>}
                    variant="contained"
                    onClick={signInWithGoogle}
                >
                    Mit Google anmelden
                </Button>
            </>
        }
    </div>;
}
